import { makeStyles } from '@barracuda-internal/bds-core'

export const SIDE_MENU_WIDTH = 48
const FOOTER_HEIGHT = 0

export default makeStyles(() => ({
  app: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    overflow: 'auto'
  },
  pathWrapper: {
    height: '100%',
    display: 'flex'
  },
  wrapper: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  printable: {
    paddingLeft: 0
  },
  sidebar: {
    zIndex: 1300
  },
  hidden: {
    position: 'absolute',
    top: 0,
    left: 0,
    overflow: 'hidden',
    width: 0,
    height: 0,
    zIndex: 0,
    pointerEvents: 'none',
    opacity: 0
  }
}))
